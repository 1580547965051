<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div><router-link to="/Admin/yingqqx/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>


                    <!--<div style="width:160px;">
                        <el-select v-model="where.F_IN_JID_ID" placeholder="请选择基地" @change="selectChange()">
                            <el-option label="请选择基地" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['jid']" :label="v.F_VR_JIDMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>-->


                    <div style="width:160px;">
                        <el-select v-model="where.F_IN_YINGQ_ID" placeholder="请选择营期" @change="selectChange()">
                            <el-option label="请选择营期" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingq']" :label="v.F_IN_NAME" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>
                    <div><el-input v-model="where.F_VR_QISHU" placeholder="期数名称"></el-input></div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="70px"></el-table-column>
                    <!--<el-table-column label="封面图">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_FENGMIAN||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>-->
                    <!--<el-table-column prop="F_VR_JIDMC" label="基地"></el-table-column>-->
                    <el-table-column prop="F_IN_NAME" label="营期" width="240px"></el-table-column>
                    <el-table-column prop="F_VR_QISHU" label="期数"></el-table-column>
                    <el-table-column prop="adv_start" label="开始时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_VR_QISHI<=0"> - </div>
                            <div v-else>{{scope.row.F_VR_QISHI|formatDate}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="adv_end" label="结束时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_VR_JIEZHI<=0"> - </div>
                            <div v-else>{{scope.row.F_VR_JIEZHI|formatDate}}</div>
                        </template>
                    </el-table-column>
                    
                    <!--<el-table-column prop="F_VR_KECJG" label="课程价格"></el-table-column>
                    <el-table-column prop="F_IN_JIAGE" label="定金"></el-table-column>-->
                    <el-table-column prop="F_IN_SHENGYSL" label="剩余课程数量"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'yingqqx_form',params:{id:scope.row.F_IN_ID}})">编辑</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
          },
          info:[],
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getYingqqxList,this.where).then(res=>{
                this.page_size = res.data.info.per_page;
                this.total_data = res.data.info.total;
                this.current_page = res.data.info.current_page;
                this.list = res.data;
                this.info = res.data.info.data;
                console.log(res.data.info.data)
            })

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delYingqqx,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>